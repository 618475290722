import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useAccount, useSearch } from '../../../../frontastic';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import EnvelopeIcon from '../../../icons/envelope';
import ChevronDownIcon from '../../../icons/chevron-down';
import ChevronUpIcon from '../../../icons/chevron-up';
import CheckCrossIcon from '../../../icons/check-cross';
import CheckCircleIcon from '../../../icons/check-circle';
import EditIcon from '../../../icons/edit';
import FormFieldInput from '../../forms/form-field-input';
import FormFieldCheckbox from '../../forms/form-field-checkbox';
import { TermFacet } from '@Types/result/TermFacet';
import { RangeFacet } from '@Types/result/RangeFacet';
import { Category } from '@Types/product/Category';
import { NotificationRequest } from '@Types/account';
import { Facet } from '@Types/result/Facet';
import { CurrencyHelpers } from '../../../../helpers/currencyHelpers';
import Spinner from '../../../commercetools-ui/spinner';
import { CheckIcon, PlusIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { Disclosure } from '@headlessui/react';
import { FilterUtils } from '../../utils/FilterUtils';
import classnames from 'classnames';
import styles from '../bike-alarm.module.scss';
import { validateEmail } from '../../../../helpers/EmailValidatorHelper';

export interface Props {
  headline: string;
  subline: string;
  searchLabel: string;
  emailLabel: string;
  disclaimer: string;
  newsletterLabel: string;
  btnLabel: string;
  filteredTermFacets: TermFacet[];
  filteredRangeFacets: RangeFacet[];
  categoryTitle: string;
  category: Category;
  visibleFilters?: string[];
  facets: Facet[];
}

const BikeAlarmOverview: React.FC<Props> = ({
  headline,
  subline,
  searchLabel,
  emailLabel,
  disclaimer,
  newsletterLabel,
  btnLabel,
  filteredTermFacets,
  filteredRangeFacets,
  categoryTitle,
  category,
  visibleFilters,
  facets,
}) => {
  const { account, loggedIn } = useAccount();
  const prefilledTitle = `${categoryTitle} ${filteredTermFacets
    .flatMap((facet) => facet.terms.map((term) => term.label))
    .slice(0, 2)
    .join(', ')}`;
  const [data, setData] = useState({
    category: category,
    searchTitle: categoryTitle ? prefilledTitle : '',
    selectedFilterValues: filteredTermFacets,
    selectedRangeFilterValues: filteredRangeFacets,
    email: loggedIn ? account.email : '',
    newsletter: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<number>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(undefined);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [newFilter, setNewFilter] = useState<boolean>(false);
  const [isFormFilled, setIsFormFilled] = useState<boolean>(false);
  const [filteredFacets, setFilteredFacets] = useState<Facet[]>(
    FilterUtils.sortFacetsByList(FilterUtils.filterFacetsByType(facets, 'term'), visibleFilters).filter(
      (item) => !item.selected,
    ),
  );

  const { formatMessage } = useFormat({ name: 'bikealarm' });

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });
  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = formMethods;

  const { addNotification } = useAccount();
  const { getFacetValues } = useSearch();

  const updateSearchParams = (field: string, value: string) => {
    setData({ ...data, [field]: value });
    setIsFormFilled(value?.length > 0);
  };

  const deselectTerm = (event) => {
    const clickedTerm = data.selectedFilterValues
      .filter((filter) => filter.identifier === event.target.name)[0]
      .terms.filter((term) => term.identifier === event.target.value)[0];
    clickedTerm.selected = false;
    setData({ ...data });
  };

  const handleTermInput = (event) => {
    const clickedTerm = data.selectedFilterValues
      .filter((filter) => filter.identifier === event.target.name)[0]
      .terms.filter((term) => term.identifier === event.target.value)[0];
    clickedTerm.selected = !clickedTerm.selected;
    setData({ ...data });
  };

  const openEditMode = () => {
    setEditMode(true);
    const reloadedFacets = data.selectedFilterValues.map((facet) => facet.identifier);

    getFacetValues(reloadedFacets).then((result: TermFacet[]) => {
      for (const selectedFilter of data.selectedFilterValues) {
        const resultFilter = result.find((filter) => filter.identifier === selectedFilter.identifier);
        if (resultFilter?.terms?.length) {
          selectedFilter.terms = [
            ...selectedFilter.terms,
            ...resultFilter.terms.filter(
              (term) => !selectedFilter.terms.some((selectedTerm) => selectedTerm.identifier === term.identifier),
            ),
          ].sort((term1, term2) =>
            term1.label.toLowerCase() === term2.label.toLowerCase()
              ? 0
              : term1.label.toLowerCase() > term2.label.toLowerCase()
              ? 1
              : -1,
          );
        }
      }
    });
  };

  const closeEditMode = () => {
    setNewFilter(false);
    setEditMode(false);

    for (const selectedFilter of data.selectedFilterValues) {
      selectedFilter.terms = selectedFilter.terms.filter((term) => term.selected === true);
      selectedFilter.selected = selectedFilter.terms.length > 0;
    }
    data.selectedFilterValues = data.selectedFilterValues.filter((filter) => filter.selected === true);
    setFilteredFacets(
      FilterUtils.sortFacetsByList(FilterUtils.filterFacetsByType(facets, 'term'), visibleFilters).filter(
        (filter) =>
          !filter.selected &&
          !data.selectedFilterValues.some((selectedFilter) => filter.identifier === selectedFilter.identifier),
      ),
    );
    setData({ ...data });
  };

  const handleOnChange = (event) => {
    setNewFilter(false);
    const optionValue = event.target.value;

    getFacetValues(optionValue).then((result: TermFacet) => {
      setFilteredFacets(filteredFacets.filter((facet) => facet.identifier !== result.identifier));
      result.selected = true;
      result.terms.sort((term1, term2) =>
        term1.label.toLowerCase() === term2.label.toLowerCase()
          ? 0
          : term1.label.toLowerCase() > term2.label.toLowerCase()
          ? 1
          : -1,
      );
      data.selectedFilterValues.push(result);
      setData({ ...data });
    });
  };

  const onSubmit = async (formData) => {
    closeEditMode();

    const { selectedFilterValues, selectedRangeFilterValues, email, newsletter, searchTitle, category } = data;
    const termFilters = selectedFilterValues.map(({ identifier, type, terms }) => ({
      filterId: identifier,
      type,
      values: terms.map((term) => term.identifier),
    }));

    const rangeFilters = selectedRangeFilterValues.map(({ identifier, type, min, minSelected, max, maxSelected }) => ({
      filterId: identifier,
      type,
      min,
      minSelected,
      max,
      maxSelected,
    }));

    const notification: NotificationRequest = {
      email,
      newsletter,
      notification: {
        title: searchTitle,
        category: {
          categoryId: category.categoryId,
          slug: category.slug,
        },
        filters: [...termFilters, ...rangeFilters],
      },
    };

    setLoading(true);
    const result = await addNotification(notification);

    setSuccess(result.success);
    setErrorCode(result.errorCode);

    if (result.success === false) {
      setErrorMessage(result.errorMessage);
    }
    setLoading(false);
  };

  const onError = (e) => {
    setErrorCode(e.errorCode);
    setSuccess(false);
  };

  const activeFilters = (
    <div>
      <div className="mb-4 last:mb-0">
        <div className="text-sm font-semibold">
          {formatMessage({ id: 'categoryLabel', defaultMessage: 'Kategorie' })}: {category.name}
        </div>
      </div>
      {data.selectedRangeFilterValues.map((range) => (
        <div key={range.key} className="mb-4 last:mb-0">
          <div className="mb-2 text-sm">
            {formatMessage({ id: 'priceLabel', defaultMessage: 'Preis' })}
            {editMode && (
              <span className="ml-2 text-[10px] leading-[17px] text-primary-300">
                {formatMessage({
                  id: 'onlyCustomizable',
                  defaultMessage: '(nur über die Filter in der Listenansicht anpassbar)',
                })}
              </span>
            )}
          </div>
          <div className="flex gap-1 overflow-auto md:flex-wrap">
            <span className="rounded border border-primary-100 px-2.5 py-1.5 text-xs">
              {CurrencyHelpers.formatForCurrency(range.minSelected)} -{' '}
              {CurrencyHelpers.formatForCurrency(range.maxSelected)}
            </span>
          </div>
        </div>
      ))}
      {data.selectedFilterValues.length > 0 && (
        <>
          {data.selectedFilterValues.map((facet) => (
            <div key={facet.key}>
              {(facet.selected || editMode) && (
                <div className="mb-2 mt-4 last:mb-0">
                  <div className="text-sm">{facet.label}</div>
                  {facet.terms.find((term) => term.selected) && (
                    <div className="mt-2 flex gap-1 overflow-auto md:flex-wrap">
                      {facet.terms
                        .filter((term) => term.selected)
                        .map((term) => (
                          <span
                            key={`${facet.key}.${term.key}`}
                            className="flex items-center rounded border border-primary-100 px-2.5 py-1.5 text-xs"
                          >
                            {term.label}
                            {editMode && (
                              <button
                                type="button"
                                name={facet.identifier}
                                value={term.identifier}
                                onClick={(event) => deselectTerm(event)}
                              >
                                <XIcon className="pointer-events-none ml-1.5 inline-block h-3 w-3 text-neutral-900" />
                              </button>
                            )}
                          </span>
                        ))}
                    </div>
                  )}
                </div>
              )}

              {editMode && (
                <>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={classnames(
                            'flex w-full items-center justify-between border-2 border-primary-100 px-4 py-3 text-base text-[#ACB0B0] last:mb-0 focus:outline-none sm:text-sm',
                            open ? 'rounded-b-none rounded-t-lg' : 'rounded-lg',
                          )}
                        >
                          <span className="">
                            {facet.label}
                            {formatMessage({ id: 'selectLabel', defaultMessage: ' auswählen' })}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <ChevronUpIcon className="block h-5 w-5 text-primary-400" />
                            ) : (
                              <ChevronDownIcon className="block h-5 w-5 text-primary-400" />
                            )}
                          </span>
                        </Disclosure.Button>
                        <Disclosure.Panel className="flex max-h-[182px] flex-col gap-2 overflow-y-auto rounded-b-lg border-2 border-t-0 border-primary-100 p-4">
                          {facet.terms.map((term) => (
                            <div className="relative flex items-start" key={term.identifier}>
                              <div className="flex h-5 items-center">
                                <input
                                  type="checkbox"
                                  className="h-5 w-5 cursor-pointer rounded border-gray-300 text-transparent"
                                  defaultChecked={term.selected}
                                  onChange={(event) => handleTermInput(event)}
                                  value={term.identifier}
                                  name={facet.identifier}
                                  id={term.identifier}
                                />
                              </div>
                              <div className="ml-2 text-sm">
                                <label
                                  htmlFor={term.identifier}
                                  className="cursor-pointer text-xs text-gray-700 dark:text-light-100"
                                >
                                  {term.label}
                                </label>
                              </div>
                            </div>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );

  return (
    <div className={styles.formWrapper}>
      {errorCode === null && !success && (
        <FormProvider {...formMethods}>
          <div className="mt-6 text-center text-2xl font-bold">{headline}</div>
          <div
            className="my-8 text-sm text-neutral-900"
            dangerouslySetInnerHTML={{
              __html: subline,
            }}
          ></div>
          {loading && (
            <div className="absolute flex size-full items-stretch justify-center bg-white/50 px-12 py-10">
              <Spinner />
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="flex flex-col gap-8">
              <div className="flex-auto">
                <FormFieldInput
                  name="searchTitle"
                  inputType="text"
                  value={data.searchTitle || ''}
                  onChange={(name, value) => updateSearchParams(name, value)}
                  containerClassNames="col-span-6 sm:col-span-6"
                  inputClassNames="block w-full rounded-lg border-2 border-gray-300 text-sm px-4 py-3 focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
                  labelClassNames="mb-1 block text-xs font-normal text-neutral-400 dark:text-light-100"
                  label={searchLabel}
                  placeholder="Titel"
                  validation={{ required: true, maxLength: 64 }}
                />
              </div>
              {activeFilters}
              {!editMode ? (
                <button className="flex gap-2 text-blue-600" onClick={() => openEditMode()}>
                  <EditIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
                  <span className="text-xs font-bold underline">
                    {formatMessage({ id: 'editFilter', defaultMessage: 'Suchkriterien editieren' })}
                  </span>
                </button>
              ) : (
                <>
                  {newFilter ? (
                    <div>
                      <div className="mb-2 text-sm">
                        {formatMessage({ id: 'addNewFilter', defaultMessage: 'Neuen Filter wählen' })}
                      </div>
                      <select
                        name="add-filter"
                        id="add-filter-select"
                        className="flex w-full items-center justify-between rounded-lg border-2 border-primary-100 px-4 py-3 text-base text-[#ACB0B0] last:mb-0 focus:outline-none sm:text-sm"
                        onChange={(event) => handleOnChange(event)}
                        defaultValue="DEFAULT"
                      >
                        <option value="DEFAULT" disabled>
                          {formatMessage({ id: 'selectFilter', defaultMessage: 'Filter auswählen' })}
                        </option>
                        {filteredFacets.map((facet, index) => (
                          <option key={index} value={facet.identifier}>
                            {facet.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <button onClick={() => setNewFilter(true)} className="flex gap-2 text-blue-600">
                      <PlusIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
                      <span className="text-xs font-bold underline">
                        {formatMessage({ id: 'addMoreFilters', defaultMessage: 'Weitere Filter hinzufügen' })}
                      </span>
                    </button>
                  )}
                  <button
                    onClick={() => closeEditMode()}
                    type="button"
                    className="flex min-w-[250px] items-center justify-center gap-2 rounded-md border-2 border-blue-600 bg-neutral-100 px-6 py-3 text-sm font-semibold text-blue-600 transition duration-150 ease-out hover:border-hollow-text hover:bg-hollow-hover focus:border-hollow-text focus:bg-hollow-dark focus:outline-none focus:ring-2 focus:ring-hollow-dark focus:ring-offset-2 disabled:bg-hollow disabled:text-hollow-dark"
                  >
                    <CheckIcon className="h-5 w-5 shrink-0 text-blue-600" aria-hidden="true" />
                    <span>{formatMessage({ id: 'saveSearch', defaultMessage: 'Anpassungen speichern' })}</span>
                  </button>
                </>
              )}
              <div className={`flex flex-wrap gap-4 ${styles.disclaimerWrapper}`}>
                <div className="flex-auto">
                  <FormFieldInput
                    name="email"
                    inputType="email"
                    inputAutoComplete="email"
                    value={data.email || ''}
                    onChange={(name, value) => updateSearchParams(name, value)}
                    validation={{ validate: (value) => validateEmail(value) }}
                    containerClassNames="col-span-6 sm:col-span-6"
                    inputClassNames="block w-full rounded-lg border-2 text-sm border-gray-300 px-4 py-3 focus:border-primary-400 focus:ring-primary-400 sm:text-sm read-only:text-primary-100 read-only:!border-primary-100 read-only:!shadow-none read-only:!ring-transparent"
                    labelClassNames="mb-1 block text-xs font-normal text-neutral-400 dark:text-light-100"
                    label={emailLabel}
                    placeholder="E-Mail"
                    isReadOnly={loggedIn}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <FormFieldCheckbox
                    checked={data.newsletter}
                    onChange={(checked) => {
                      if (checked !== undefined) {
                        setData({ ...data, newsletter: checked });
                      }
                    }}
                    name="newsletter"
                    label={newsletterLabel}
                    inverseLabel
                    containerClassNames="flex items-center gap-2"
                    inputClassNames="h-5 w-5 rounded-lg border-primary-100 text-transparent"
                    labelClassNames="text-xs font-light"
                    validation={{ required: false }}
                  />
                </div>
                <div
                  className="text-xs font-light text-neutral-900"
                  dangerouslySetInnerHTML={{
                    __html: disclaimer,
                  }}
                ></div>
              </div>
            </div>
            <button
              className={`mt-8 flex w-full items-center justify-center gap-2 rounded-md  px-4 py-3 ${
                !isDirty || !isValid ? 'cursor-not-allowed bg-neutral-400' : 'bg-accent-200'
              }`}
              disabled={!isDirty || !isValid}
            >
              <EnvelopeIcon className="size-6 text-primary-100" aria-hidden="true" />
              <div className="text-sm text-font-light">{btnLabel}</div>
            </button>
          </form>
        </FormProvider>
      )}
      {success && (
        <div>
          <div className="flex flex-col gap-8">
            <div className="pt-6 text-center text-2xl font-bold text-neutral-900">
              {formatMessage({ id: 'successHeadline', defaultMessage: 'Last Step!' })}
            </div>
            <div className="mb-8 flex items-center gap-3 rounded-lg border-2 border-blue-600 bg-blue-300 px-4 py-2.5">
              <CheckCircleIcon className="size-6 shrink-0" aria-hidden="true" />
              <div className="text-sm text-blue-600">
                {formatMessage({ id: 'successMessage', defaultMessage: 'Die Email wurde erfolgreich verschickt.' })}
              </div>
            </div>
          </div>
        </div>
      )}
      {errorCode !== null && success === false && (
        <div>
          <div className="flex flex-col gap-8">
            <div className="pt-6 text-center text-2xl font-bold text-neutral-900">
              {formatMessage({ id: 'errorHeadline', defaultMessage: 'Oh no!' })}
            </div>
            <div className="mb-8 flex items-center gap-3 rounded-lg border-2 border-error-400 bg-error-200 px-4 py-2.5">
              <CheckCrossIcon className="size-6 shrink-0" aria-hidden="true" />
              <div className="text-sm text-error-400">
                <p>
                  {formatMessage({
                    id: 'errorMessage',
                    defaultMessage:
                      'Deine Suche konnten wir leider nicht anlegen. Bitte versuche es nochmal oder kontaktiere unseren Customer Support.',
                  })}
                </p>
                {errorMessage && <p className="mt-2">{errorMessage}</p>}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BikeAlarmOverview;
