import React from 'react';
import Countdown from '../../../../components/revelo-ui/content/countdown-banner/countdown';
import { LocaleStorage } from '../../../../frontastic';
import Image, { MediaItemWithMods } from '../../../../frontastic/lib/image';
import { Reference, ReferenceLink } from '../../../../helpers/reference';
import DataValidator from '../../../../helpers/utils/dataValidator';
import { isValidDateTimeFormat } from '../../../../helpers/utils/dateTimeUtils.js';
import { mapLanguage } from '../../../../project.config';
import Button from '../button';
import Markdown from '../markdown';

export interface Props {
  backgroundImage?: {
    media?: MediaItemWithMods;
    title?: string;
  };
  intrinsicSize?: '1400' | '700' | '460' | '350';
  backgroundColor?: string;
  enableBannerLink?: boolean;
  bannerLink?: Reference;
  markdownText?: string;
  endDate?: string;
  textColor?: string;
  buttonLabel?: string;
  buttonLink?: Reference;
  buttonStyle?: 'primary' | 'secondary' | 'hollow';
}

const CountdownBanner: React.FC<Props> = ({
  backgroundImage,
  intrinsicSize,
  backgroundColor,
  enableBannerLink,
  bannerLink,
  markdownText,
  endDate,
  textColor,
  buttonLabel,
  buttonLink,
  buttonStyle,
}) => {
  const locale = mapLanguage(LocaleStorage?.locale ?? 'de');

  const validEndDate = isValidDateTimeFormat(endDate);

  const validBannerLink = DataValidator.isValidReference(bannerLink) ? bannerLink : undefined;
  const validButtonLink = DataValidator.isValidReference(buttonLink) ? buttonLink : undefined;

  const txtColor = textColor || '#161038';
  let bgColor = backgroundColor;
  if (!backgroundImage?.media && !backgroundColor) {
    bgColor = '#FFFCFA';
  }

  return (
    <div
      className="countdown-banner relative left-1/2 m-0 -ml-[50vw] w-screen px-3 py-4 text-center"
      style={{ backgroundColor: bgColor }}
    >
      {enableBannerLink && validBannerLink && (
        <ReferenceLink target={validBannerLink} className="absolute inset-0 z-[2]" />
      )}

      {backgroundImage?.media && (
        <div className="absolute inset-0 z-0">
          <Image
            media={backgroundImage.media}
            alt={backgroundImage?.['title']?.[locale] ?? 'countdown banner image'}
            layout="fill"
            className="object-cover"
            intrinsicSize={+intrinsicSize}
          />
        </div>
      )}

      <div className={'relative z-[1]'} style={{ color: txtColor }}>
        {markdownText && <Markdown text={markdownText} className={`mb-4 text-xs`} />}

        {validEndDate && <Countdown validEndDate={validEndDate} className={`mb-4 flex justify-center gap-2`} />}
      </div>

      {buttonLabel && validButtonLink && (
        <Button type={buttonStyle} text={buttonLabel} reference={validButtonLink} className={'relative z-[3]'} />
      )}
    </div>
  );
};

export default CountdownBanner;
