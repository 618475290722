import React from 'react';
import { FieldError } from 'react-hook-form';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { getErrorIdForField } from '../../utils/FormFieldUtils';

interface Props {
  readonly error?: FieldError;
  readonly formId?: string;
  readonly fieldId?: string;
  readonly className?: string;
}

const ErrorElement = ({ error, formId, fieldId, className }: Props) => {
  const { formatMessage } = useFormat({ name: 'error' });

  const getErrorMessage = (error?: FieldError) => {
    const errorId = getErrorIdForField(fieldId, error, formId);
    const errorMessage = formatMessage({ id: errorId });

    return errorId !== errorMessage ? errorMessage : error !== null ? getErrorMessage(null) : errorId;
  };

  return (
    <>
      {error && (
        <div className={className || 'font-base mt-2 block px-2 text-sm text-red-500 dark:text-light-100'}>
          {getErrorMessage(error)}
        </div>
      )}
    </>
  );
};

export default ErrorElement;
